import { checkTokenExpiration } from "../utils";

checkTokenExpiration();

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: localStorage.getItem("token"),
};

const domain = window.location.hostname.includes("localhost") ? "http://localhost:5000/v1/api" :  "https://api.supergympro.uy/v1/api";

const get = async (path) => {
  const response = await fetch(domain + path, {
    method: "GET",
    headers,
  });
  return await response.json();
};

const post = async (path, body) => {
  const response = await fetch(domain + path, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  return await response.json();
};

const patch = async (path, body) => {
  const response = await fetch(domain + path, {
    method: "PATCH",
    headers,
    body: JSON.stringify(body),
  });
  return await response.json();
};

const _delete = async (path) => {
  const response = await fetch(domain + path, {
    method: "DELETE",
    headers,
  });
  return await response.json();
};

export const http = {
  get,
  post,
  patch,
  delete: _delete,
};
