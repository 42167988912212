import { articleRepository } from "../../infrastructure/repositories/article.repository";
import { ArticleSave, ArticleUpdate } from "../models/article.model";

export const getAll = async () => {
  return await articleRepository.getAll();
};

export const getByName = async (name: String) => {
  return await articleRepository.getByName(name);
};

export const create = async (user: ArticleSave) => {
  return await articleRepository.create(user);
};

export const update = async (user: ArticleUpdate) => {
  return await articleRepository.update(user);
};

export const _delete = async (id: Number) => {
  return await articleRepository._delete(id);
};