import {
  DashboardPage,
  CategorysPage,
  ArticlesPage,
  BrandsPage,
  ProjectsPage,
  SalesPage,
  UsersPage
} from "../pages";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "apps-outline",
    component: DashboardPage,
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "bookmarks-outline",
    component: CategorysPage,
  },
  {
    path: "/brands",
    name: "Brands",
    icon: "ribbon-outline",
    component: BrandsPage,
  },
  {
    path: "/articles",
    name: "Articles",
    icon: "cart-outline",
    component: ArticlesPage,
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "reader-outline",
    component: ProjectsPage,
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "pricetags-outline",
    component: SalesPage,
  },
  {
    path: "/users",
    name: "Users",
    icon: "people-outline",
    component: UsersPage,
  }
];

export default routes;
