import { UserUpdate } from "../../domain/models/user.model";
import { http } from "../http";

export const userRepository = {
  getAll: async () => {
    return await http.get(`/user`);
  },

  create: async (user: object) => {
    return await http.post('/user', user);
  },

  update: async (user: UserUpdate) => {
    const {id} = user;
    delete user.id;
    return await http.patch(`/user/${id}`, user);
  },

  _delete: async (id: Number) => {
    return await http.delete(`/user/${id}`);
  },

//   changePassword: async (data: object) => {
//     return await http.post<ChangePasswordRequestDTO>(`${config.INTERNAL_URL_BACKEND}/changePassword`, data);
//   }
};