import React from "react";
import { Navigation, Ul, Li, Ancla, Icon, Title } from "./style";
import { Link } from 'react-router-dom';

function NavigationComponent({ toogle, routes }) {
  return (
    <Navigation toogle={toogle}>
      <Ul>
        <Li>
          <Ancla>
            <img src={require("../../../assets/img/supergym.png")} alt="SuperGym" />
          </Ancla>
        </Li>
        {routes.map((prop) => {
          if (prop.invisible) return null;
          if (!prop.redirect)
            return (
              <Li key={prop.name}>
                <Link to={prop.path} className="ancla-nav">
                    <Icon>
                      <ion-icon name={prop.icon}></ion-icon>
                    </Icon>
                    <Title>{prop.name}</Title>
                </Link>
              </Li>
            );
        })}
      </Ul>
    </Navigation>
  );
}

export default NavigationComponent;
