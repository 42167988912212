
import { ProjectSave, ProjectUpdate } from "../../domain/models/project.model";
import { http } from "../http";

export const projectRepository = {
  getAll: async () => {
    return await http.get(`/project`);
  },

  getByName: async (name: String) => {
    return await http.get(`/project/getByName/${name}`);
  },

  create: async (project: ProjectSave) => {
    return await http.post("/project", project);
  },

  update: async (project: ProjectUpdate) => {
    const { id } = project;
    delete project.id;
    return await http.patch(`/project/${id}`, project);
  },

  _delete: async (id: Number) => {
    return await http.delete(`/project/${id}`);
  },

  //   changePassword: async (data: object) => {
  //     return await http.post<ChangePasswordRequestDTO>(`${config.INTERNAL_URL_BACKEND}/changePassword`, data);
  //   }
};
