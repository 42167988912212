import styled from "styled-components";

const PrimaryButton = styled.button`
  width: 140px;
  height: 45px;
  margin: 5px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.5px;
  background-color: var(--accentBlue);
  color: var(--white);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px var(--black2);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  :hover {
    box-shadow: 0px 15px 20px var(--blue);
    transform: translateY(-7px);
  }
`;

const SecondaryButton = styled.button`
  width: 140px;
  height: 45px;
  margin: 5px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.5px;
  background-color: var(--white);
  color: var(--accentBlue);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px var(--black2);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  :hover {
    box-shadow: 0px 15px 20px var(--blue);
    transform: translateY(-7px);
  }
`;

export { PrimaryButton, SecondaryButton };
