
import { TagSave, TagUpdate } from "../../domain/models/tag.model";
import { http } from "../http";

export const tagRepository = {
  getAll: async () => {
    return await http.get(`/tag`);
  },

  create: async (tag: TagSave) => {
    return await http.post('/tag', {name: tag});
  },

  update: async (tag: TagUpdate) => {
    const {id} = tag;
    delete tag.id;
    return await http.patch(`/tag/${id}`, tag);
  },

  _delete: async (id: Number) => {
    return await http.delete(`/tag/${id}`);
  },

//   changePassword: async (data: object) => {
//     return await http.post<ChangePasswordRequestDTO>(`${config.INTERNAL_URL_BACKEND}/changePassword`, data);
//   }
};