import React, { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { Table, BtnPrimary, Spinner } from "../../components";
import Template from "./Template";
import { AlignRight, Centered } from "../style";
import { getAll, _delete } from "../../../domain/services/article.service";

function ArticlePage() {
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState(null);
  const [action, setAction] = useState(0); //1 - new, 2 = edit, 3 = delete
  const [loading, setLoading] = useState(false);

  const cells = ["id", "code", "name", "slug", "price", "stock"];

  useEffect(() => {
    setLoading((loading) => !loading);
    getAllArticles();
  }, [action]);

  const getAllArticles = async () => {
    try {
      let response = await getAll();
      setArticles(response);
      setLoading((loading) => !loading);
    } catch (error) {
      toast.error(error.message);
      setLoading((loading) => !loading);
    }
  };

  const deleteArticle = (item) => {
    try {
      _delete(item.id);
      toast.success("Article deleted successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEdit = (item) => {
    setArticle(() => item);
    setAction(2);
  };

  const handleDelete = (item) => {
    deleteArticle(item);
    setAction(3);
  };

  return (
    <Fragment>
      {(action === 0 || action === 3) && (
        <Fragment>
          <h1 className="text-center">Articles</h1>
          <AlignRight>
            <BtnPrimary onClick={() => setAction(1)}>Nuevo</BtnPrimary>
          </AlignRight>
          <Table
            title="All articles"
            cells={cells}
            items={articles}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
          {loading && (
            <Centered>
              <Spinner />
            </Centered>
          )}
        </Fragment>
      )}
      {action !== 0 && action !== 3 && (
        <Fragment>
          <Template
            action={action}
            article={article}
            close={() => setAction(0)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default ArticlePage;
