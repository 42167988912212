import { tagRepository } from "../../infrastructure/repositories/tag.repository";
import { TagSave, TagUpdate } from "../models/tag.model";

export const tagsGetAll = async () => {
  return await tagRepository.getAll();
};

export const tagCreate = async (tag: TagSave) => {
  return await tagRepository.create(tag);
};

export const update = async (tag: TagUpdate) => {
  return await tagRepository.update(tag);
};

export const _delete = async (id: Number) => {
  return await tagRepository._delete(id);
};