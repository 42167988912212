import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import App from "./infrastructure/App";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./infrastructure/hooks/useAuth";
import ProtectedRoute from "./infrastructure/components/ProtectedRoute";
import { AuthPage } from "./infrastructure/pages";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <Route exact path="/auth" component={AuthPage} />
        <ProtectedRoute path="/" component={App} />
      </Switch>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
