import { categoryRepository } from "../../infrastructure/repositories/category.repository";
import { CategorySave, CategoryUpdate } from "../models/category.model"

export const categoriesGetAll = async () => {
  return await categoryRepository.getAll();
};

export const create = async (user: CategorySave) => {
  return await categoryRepository.create(user);
};

export const update = async (user: CategoryUpdate) => {
  return await categoryRepository.update(user);
};

export const _delete = async (id: Number) => {
  return await categoryRepository._delete(id);
};