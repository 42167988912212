import React, { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { Table, BtnPrimary, Spinner } from "../../components";
import Template from "./Template";
import { AlignRight, Centered } from "../style";
import { brandsGetAll, _delete } from "../../../domain/services/brand.service";

function BrandPage() {
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [action, setAction] = useState(0); //1 - new, 2 = edit, 3 = delete
  const [loading, setLoading] = useState(false);

  const cells = ["id", "name", "aditionalData", "published"];

  useEffect(() => {
    setLoading((loading) => !loading);
    setTimeout(() => {
      getAllBrands();
    }, 1500);
  }, [action]);

  const getAllBrands = async () => {
    try {
      let response = await brandsGetAll();

      response.forEach((item) => {
        delete item.status;
        delete item.createdAt;
        delete item.updatedAt
        delete item.articles
        delete item.images
        delete item.tags
      });

      setBrands(response);
      setLoading((loading) => !loading);
    } catch (error) {
      toast.error(error.message);
      setLoading((loading) => !loading);
    }
  };

  const deleteBrand = (item) => {
    try {
      _delete(item.id);
      toast.success("Brand deleted successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEdit = (item) => {
    setBrand(() => item);
    setAction(2);
  };

  const handleDelete = (item) => {
    deleteBrand(item)
    setAction(3);
  };

  return (
    <Fragment>
      {(action === 0 || action === 3) && (
        <Fragment>
          <h1 className="text-center">Brands</h1>
          <AlignRight>
            <BtnPrimary onClick={() => setAction(1)}>Nuevo</BtnPrimary>
          </AlignRight>
          <Table
            title="All brands"
            cells={cells}
            items={brands}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
          {loading && (
            <Centered>
              <Spinner />
            </Centered>
          )}
        </Fragment>
      )}
      {(action !== 0 && action !== 3) && (
        <Fragment>
          <Template action={action} brand={brand} close={() => setAction(0)} />
        </Fragment>
      )}
    </Fragment>
  );
}

export default BrandPage;
