import React, { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { BtnPrimary, BtnSecondary, Input, Switch } from "../../components";
import { TemplateContainer, Form, FormGroup, Centered } from "../style";

import { create, update } from "../../../domain/services/category.service";

const Template = ({ action, category, close }) => {
  const [form, setForm] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (action === 2) setForm(category);
  }, [action]);

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createCategory(form) : updateCategory(form);
  };

  const createCategory = (_form) => {
    try {
      create(_form);
      toast.success("Category created successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateCategory = async (_form) => {
    try {
      let updateFields = {};
      var aKeys = Object.keys(category).sort();
      var bKeys = Object.keys(_form).sort();

      for (var i = 0; i < aKeys.length; i++) {
        if (category[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      updateFields["id"] = category.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Category updated successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  return (
    <Fragment>
      <h1 className="text-center">Categories</h1>
      <TemplateContainer>
        <Centered>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <span>Name</span>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={form.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Description</span>
              <Input
                type="text"
                name="description"
                placeholder="Description"
                value={form.description}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Slug</span>
              <Input
                type="text"
                name="slug"
                placeholder="Slug"
                value={form.slug}
                onChange={handleChange}
              />
            </FormGroup>
            <Centered>
              <BtnSecondary onClick={() => close()}>Cancel</BtnSecondary>
              <BtnPrimary type="submit">Save</BtnPrimary>
            </Centered>
          </Form>
        </Centered>
      </TemplateContainer>
    </Fragment>
  );
};

export default Template;
