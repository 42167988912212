import styled from "styled-components";

const CardBox = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-gap: 30px;  
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: repeat(2, 100px); 
`;

export { CardBox };
