
import { BrandSave, BrandUpdate } from "../../domain/models/brand.model";
import { http } from "../http";

export const brandRepository = {
  getAll: async () => {
    return await http.get(`/brand`);
  },

  create: async (brand: BrandSave) => {
    return await http.post('/brand', brand);
  },

  update: async (brand: BrandUpdate) => {
    const {id} = brand;
    delete brand.id;
    return await http.patch(`/brand/${id}`, brand);
  },

  _delete: async (id: Number) => {
    return await http.delete(`/brand/${id}`);
  },

//   changePassword: async (data: object) => {
//     return await http.post<ChangePasswordRequestDTO>(`${config.INTERNAL_URL_BACKEND}/changePassword`, data);
//   }
};