import { userRepository } from "../../infrastructure/repositories/user.repository";
import { UserUpdate } from "../models/user.model"

export const getAll = async () => {
  return await userRepository.getAll();
};

export const create = async (user: object) => {
  return await userRepository.create(user);
};

export const update = async (user: UserUpdate) => {
  return await userRepository.update(user);
};

export const _delete = async (id: Number) => {
  return await userRepository._delete(id);
};

// const changePassword = (data: object) => {
//   return userRepository.changePassword(data);
// };