import React, { Fragment, useState, useEffect } from "react";

import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";

import toast from "react-hot-toast";
import Multiselect from "multiselect-react-dropdown";

import {
  BtnPrimary,
  BtnSecondary,
  Input,
  ImageUpload,
  Editor,
  Modal,
} from "../../components";
import {
  TemplateContainer,
  Form,
  FormGroup,
  Centered,
  AlignRight,
  StyledIcon,
} from "../style";

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { create, update } from "../../../domain/services/article.service";
import { brandsGetAll } from "../../../domain/services/brand.service";
import { categoriesGetAll } from "../../../domain/services/category.service";
import { tagsGetAll, tagCreate } from "../../../domain/services/tag.service";

SwiperCore.use([EffectCoverflow, Pagination]);

const Template = ({ action, article, close }) => {
  const [form, setForm] = useState({
    code: "",
    name: "",
    description: null,
    slug: "",
    price: 0,
    stock: 0,
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [articleBrands, setArticleBrands] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [articleTags, setArticleTags] = useState([]);
  const [articleImages, setArticleImages] = useState([]);

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const [oversized, setOversized] = useState(false);
  const [modalTag, setModalTag] = useState(false);

  const [newTagValue, setNewTagValue] = useState("");

  useEffect(() => {
    getAllBrands();
    getAllCategories();
    getAllTags();
    if (action === 2) {
      article.brands.length > 0 && setArticleBrands(article.brands);
      article.categories.length > 0 && setArticleCategories(article.categories);
      article.tags.length > 0 && setArticleTags(article.tags);
      article.images.length > 0 && setArticleImages(article.images);

      article.description &&
        setEditorState(htmlToDraftBlocks(article.description));
      setForm(article);
    }
  }, [action]);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const getAllCategories = async () => {
    try {
      const res = await categoriesGetAll();
      setCategories(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAllBrands = async () => {
    try {
      const res = await brandsGetAll();
      setBrands(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAllTags = async () => {
    try {
      const res = await tagsGetAll();
      setTags(() => res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createArticle(form) : updateArticle(form);
  };

  const createArticle = async (_form) => {
    try {
      if (articleBrands.length > 0) _form.brands = articleBrands;
      if (articleCategories.length > 0) _form.categories = articleCategories;
      if (articleTags.length > 0) _form.tags = articleTags;
      if (articleImages.length > 0) _form.images = articleImages;

      const res = await create(_form);
      if (res.status !== 201) {
        toast.error(res.message);
        return;
      }
      toast.success("Article created successfully");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateArticle = async (_form) => {
    try {
      let updateFields = {};
      let aKeys = Object.keys(article).sort();
      let bKeys = Object.keys(_form).sort();

      for (let i = 0; i < aKeys.length; i++) {
        if (article[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      //TODO: validar elementos nuevos o restantes
      if (
        articleBrands.length > 0 &&
        JSON.stringify(articleBrands) !== JSON.stringify(article.brands)
      )
        updateFields.brands = articleBrands;
      if (
        articleCategories.length > 0 &&
        JSON.stringify(articleCategories) !== JSON.stringify(article.categories)
      )
        updateFields.categories = articleCategories;
      if (
        articleTags.length > 0 &&
        JSON.stringify(articleTags) !== JSON.stringify(article.tags)
      )
        updateFields.tags = articleTags;
      if (
        articleImages.length > 0 &&
        JSON.stringify(articleImages) !== JSON.stringify(article.images)
      )
        updateFields.images = articleImages;

      if (Object.entries(updateFields).length === 0) {
        toast.success("Nothing to update");
        return;
      }

      updateFields.id = article.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Article updated successfully");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createNewTag = async () => {
    try {
      const res = await tagCreate(newTagValue);
      if (res.status && res.status !== 201) {
        toast.error(res.message);
        return;
      }
      toast.success("Tag created successfully");
      getAllTags()
      setNewTagValue("")
      setModalTag(!modalTag);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isPriceOrStock = name === "price" || name === "stock";
    setForm({ ...form, [name]: isPriceOrStock ? parseInt(value) : value });
  };

  const handleInputImageChange = (event) => {
    let files = event.target.files;

    let sizeByte = files[0].size;
    let sizekiloBytes = parseInt(sizeByte / 15360);

    if (sizekiloBytes > 15360) {
      setOversized(true);
      return;
    }
    oversized && setOversized(false);

    let reader = new FileReader();
    let nameImage = (reader.fileName = files[0].name.split(".")[0]);
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => addImage(e.target.result, nameImage);
  };

  const addImage = (_image, _name) => {
    setArticleImages([
      ...articleImages,
      { name: _name, image: _image, default: true },
    ]);
  };

  const preClose = () => {
    setArticleBrands([]);
    setArticleCategories([]);
    setArticleTags([]);
    setArticleImages([]);
    setForm({
      code: "",
      name: "",
      description: null,
      slug: "",
      price: 0,
      stock: 0,
    });
    close();
  };

  return (
    <Fragment>
      <h1 className="text-center">Articles</h1>
      <TemplateContainer>
        <Centered>
          <Modal
            title="Create a new tag"
            open={modalTag}
            valueInput={newTagValue}
            handleChangeInput={(e) => setNewTagValue(e.target.value)}
            handleSubmitInput={createNewTag}
            handleClose={() => setModalTag(!modalTag)}
          />
          <Form>
            <FormGroup>
              <span>Name</span>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={form.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Slug</span>
              <Input
                type="text"
                name="slug"
                placeholder="Slug"
                value={form.slug}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Code</span>
              <Input
                type="text"
                name="code"
                placeholder="Code"
                value={form.code}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Price</span>
              <Input
                type="number"
                name="price"
                placeholder="Price"
                value={form.price}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Stock</span>
              <Input
                type="number"
                name="stock"
                placeholder="Stock"
                value={form.stock}
                onChange={handleChange}
              />
            </FormGroup>
            {brands.length > 0 && (
              <div>
                <span className="span-custom">Brands</span>
                <Multiselect
                  placeholder={articleBrands.length <= 0 ? "Brands" : ""}
                  options={brands} // Options to display in the dropdown
                  selectedValues={articleBrands} // Preselected value to persist in dropdown
                  onSelect={(_brands) => setArticleBrands(_brands)} // Function will trigger on select event
                  onRemove={(_brands) => setArticleBrands(_brands)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            )}
            {categories.length > 0 && (
              <div>
                <span className="span-custom">Categories</span>
                <Multiselect
                  placeholder={
                    articleCategories.length <= 0 ? "Categories" : ""
                  }
                  options={categories} // Options to display in the dropdown
                  selectedValues={articleCategories} // Preselected value to persist in dropdown
                  onSelect={(_categories) => setArticleCategories(_categories)} // Function will trigger on select event
                  onRemove={(_categories) => setArticleCategories(_categories)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            )}
            {tags.length > 0 && (
              <div>
                <span className="span-custom">Tags</span>
                <Centered>
                  <Multiselect
                    placeholder={articleTags.length <= 0 ? "Tags" : ""}
                    options={tags} // Options to display in the dropdown
                    selectedValues={articleTags} // Preselected value to persist in dropdown
                    onSelect={(_tags) => setArticleTags(_tags)} // Function will trigger on select event
                    onRemove={(_tags) => setArticleTags(_tags)} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  />
                  <StyledIcon>
                    <ion-icon
                      onClick={() => setModalTag(!modalTag)}
                      name="add-circle-outline"
                      title=" "
                    ></ion-icon>
                  </StyledIcon>
                </Centered>
              </div>
            )}
          </Form>
        </Centered>
        <Centered pt="10px">
          <Editor
            name="description"
            value={editorState}
            onChange={(editorState) => {
              setForm({
                ...form,
                description: stateToHTML(editorState.getCurrentContent()),
              });
              setEditorState(editorState);
            }}
          />
        </Centered>
        <Centered pt="50px">
          <ImageUpload onChange={handleInputImageChange} />
          {oversized && (
            <span>"La imagen supera el tamaño máximo permitido de 15mb."</span>
          )}
          {articleImages.length > 0 && (
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              className="mySwiper"
            >
              {articleImages.map((img, key) => (
                <SwiperSlide key={key}>
                  <img
                    width={"100px"}
                    height={"100px"}
                    src={img.image.trim()}
                    alt={img.image.name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Centered>
        <AlignRight>
          <BtnSecondary onClick={() => preClose()}>Cancel</BtnSecondary>
          <BtnPrimary onClick={handleSubmit}>Save</BtnPrimary>
        </AlignRight>
      </TemplateContainer>
    </Fragment>
  );
};

export default Template;
