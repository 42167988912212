import { CategorySave, CategoryUpdate } from "../../domain/models/category.model";
import { http } from "../http";

export const categoryRepository = {
  getAll: async () => {
    return await http.get(`/category`);
  },

  create: async (category: CategorySave) => {
    return await http.post('/category', category);
  },

  update: async (category: CategoryUpdate) => {
    const {id} = category;
    delete category.id;
    return await http.patch(`/category/${id}`, category);
  },

  _delete: async (id: Number) => {
    return await http.delete(`/category/${id}`);
  },

//   changePassword: async (data: object) => {
//     return await http.post<ChangePasswordRequestDTO>(`${config.INTERNAL_URL_BACKEND}/changePassword`, data);
//   }
};