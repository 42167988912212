import React, { useState } from "react";
import { Toaster } from "react-hot-toast";

import { Main } from "./style";
import { Route, Switch } from "react-router-dom";
import { NavigationBar, TopBar } from "./components";

import "./assets/css/App.css";
import routes from "./routes";

function App() {
  const [toogle, setToogle] = useState(false);

  const getRoutes = (rts) => {
    return rts.map((prop, key) => {
      const Component = prop.component;
      return (
        <Route
          exact
          path={prop.path}
          render={(props) => <Component {...props} />}
          key={key}
        />
      );
    });
  };

  return (
    <div className="App">
      <div className="container">
        <Toaster position="bottom-center" reverseOrder={false} />
        <NavigationBar toogle={toogle} routes={routes} />
        <Main toogle={toogle}>
          <TopBar toogle={toogle} setToogle={(e) => setToogle(e)} />
          <div className="main-panel">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </Main>
      </div>
    </div>
  );
}

export default App;
