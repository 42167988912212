import { brandRepository } from "../../infrastructure/repositories/brand.repository";
import { BrandSave, BrandUpdate } from "../models/brand.model"

export const brandsGetAll = async () => {
  return await brandRepository.getAll();
};

export const create = async (user: BrandSave) => {
  return await brandRepository.create(user);
};

export const update = async (user: BrandUpdate) => {
  return await brandRepository.update(user);
};

export const _delete = async (id: Number) => {
  return await brandRepository._delete(id);
};