import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "../input";
import { BtnPrimary } from "../button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "45px",
  boxShadow: 24,
  p: 4,
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
};

const ModalComponent = ({
  open,
  handleClose,
  title,
  valueInput,
  handleChangeInput,
  handleSubmitInput,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <div style={style.center}>
          <Input
            type="text"
            name="tag"
            placeholder="Tag"
            value={valueInput}
            onChange={handleChangeInput}
          />
          <BtnPrimary onClick={handleSubmitInput}>Save</BtnPrimary>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
