import React, { Fragment } from "react";
import { CardBox, Card } from "../../components";

function DashboardPage() {
  const items = [
    {
      id: 1,
      name: "Categories",
      numbers: "80",
      icon: "bookmarks-outline",
    },
    {
      id: 2,
      name: "Articles",
      numbers: "1,504",
      icon: "cart-outline",
    },
    {
      id: 3,
      name: "Brands",
      numbers: "30",
      icon: "ribbon-outline",
    },
    {
      id: 4,
      name: "Projects",
      numbers: "284",
      icon: "reader-outline",
    },
    {
      id: 5,
      name: "Users",
      numbers: "6",
      icon: "people-outline",
    },
  ];
  return (
    <Fragment>
      <h1 className="text-center">Dashboard</h1>
      <CardBox>
        {items.map((item) => (
          <Fragment key={item.id}>
            <Card name={item.name} numbers={item.numbers} icon={item.icon} />
          </Fragment>
        ))}
      </CardBox>
    </Fragment>
  );
}

export default DashboardPage;
