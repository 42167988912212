import React, { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { BtnPrimary, BtnSecondary, Input } from "../../components";
import { TemplateContainer, Form, Centered } from "../style";

import { create, update } from "../../../domain/services/brand.service";

const Template = ({ action, brand, close }) => {
  const [form, setForm] = useState({
    name: "",
    aditionalData: "",
  });

  useEffect(() => {
    if (action === 2) setForm(brand);
  }, [action]);

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createBrand(form) : updateBrand(form);
  };

  const createBrand = (_form) => {
    try {
      create(_form);
      toast.success("Brand created successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateBrand = async (_form) => {
    try {
      let updateFields = {};
      var aKeys = Object.keys(brand).sort();
      var bKeys = Object.keys(_form).sort();

      for (var i = 0; i < aKeys.length; i++) {
        if (brand[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      updateFields["id"] = brand.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Brand updated successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <Fragment>
      <h1 className="text-center">Brands</h1>
      <TemplateContainer>
        <Centered>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              required
              value={form.name}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="aditionalData"
              placeholder="Aditional Data"
              required
              value={form.aditionalData}
              onChange={handleChange}
            />
            <Centered>
              <BtnSecondary onClick={() => close()}>Cancel</BtnSecondary>
              <BtnPrimary type="submit">Save</BtnPrimary>
            </Centered>
          </Form>
        </Centered>
      </TemplateContainer>
    </Fragment>
  );
};

export default Template;
