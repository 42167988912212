import { projectRepository } from "../../infrastructure/repositories/project.repository";
import { ProjectSave, ProjectUpdate } from "../models/project.model";

export const getAll = async () => {
  return await projectRepository.getAll();
};

export const getByName = async (name: String) => {
  return await projectRepository.getByName(name);
};

export const create = async (user: ProjectSave) => {
  return await projectRepository.create(user);
};

export const update = async (user: ProjectUpdate) => {
  return await projectRepository.update(user);
};

export const _delete = async (id: Number) => {
  return await projectRepository._delete(id);
};