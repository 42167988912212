import styled from "styled-components";

const Container = styled.div`
  
`;

const  StyledIcon = styled.span`
  position: relative;
  display: block;
  cursor: pointer;
  text-align: center;
  ion-icon{
    font-size: 1.40em;
  }
`;

export { Container, StyledIcon };
