import React, { Fragment, useState, useEffect } from "react";

import toast from "react-hot-toast";

import {
  BtnPrimary,
  BtnSecondary,
  Input,
  TextArea,
  ImageUpload,
} from "../../components";
import {
  TemplateContainer,
  Form,
  FormGroup,
  Centered,
  AlignRight,
} from "../style";

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { create, update } from "../../../domain/services/project.service";

SwiperCore.use([EffectCoverflow, Pagination]);

const Template = ({ action, project, close }) => {
  const [form, setForm] = useState({
    name: "",
    description: "",
  });

  const [projectImages, setPorjectImages] = useState([]);

  const [oversized, setOversized] = useState(false);

  useEffect(() => {
    if (action === 2) {
      project.images.length > 0 && setPorjectImages(project.images);
      setForm(project);
    }
  }, [action]);

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createProject(form) : updateProject(form);
  };

  const createProject = async (_form) => {
    try {
      if (projectImages.length > 0) _form.images = projectImages;

      const res = await create(_form);
      debugger
      if (res.status && res.status !== 201) {
        toast.error(res.message);
        return;
      }
      toast.success("Project created successfully");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateProject = async (_form) => {
    try {
      let updateFields = {};
      let aKeys = Object.keys(project).sort();
      let bKeys = Object.keys(_form).sort();

      for (let i = 0; i < aKeys.length; i++) {
        if (project[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      //TODO: validar elementos nuevos o restantes
      if (
        projectImages.length > 0 &&
        JSON.stringify(projectImages) !== JSON.stringify(project.images)
      )
        updateFields.images = projectImages;

      if (Object.entries(updateFields).length === 0) {
        toast.success("Nothing to update");
        return;
      }

      updateFields.id = project.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Project updated successfully");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleInputImageChange = (event) => {
    let files = event.target.files;

    let sizeByte = files[0].size;
    let sizekiloBytes = parseInt(sizeByte / 15360);

    if (sizekiloBytes > 15360) {
      setOversized(true);
      return;
    }
    oversized && setOversized(false);

    let reader = new FileReader();
    let nameImage = (reader.fileName = files[0].name.split(".")[0]);
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => addImage(e.target.result, nameImage);
  };

  const addImage = (_image, _name) => {
    setPorjectImages([
      ...projectImages,
      { name: _name, image: _image, default: true },
    ]);
  };

  const preClose = () => {
    setPorjectImages([]);
    setForm({
      name: "",
      description: "",
    });
    close();
  };

  return (
    <Fragment>
      <h1 className="text-center">Projects</h1>
      <TemplateContainer>
        <Centered>
          <Form>
            <FormGroup>
              <span>Name</span>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={form.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Description</span>
              <TextArea
                type="text"
                name="description"
                placeholder="Description"
                value={form.description}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </Centered>
        <Centered pt="50px">
          <ImageUpload onChange={handleInputImageChange} />
          {oversized && (
            <span>"La imagen supera el tamaño máximo permitido de 15mb."</span>
          )}
          {projectImages.length > 0 && (
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              className="mySwiper"
            >
              {projectImages.map((img, key) => (
                <SwiperSlide key={key}>
                  <img
                    width={"100px"}
                    height={"100px"}
                    src={img.image.trim()}
                    alt={img.image.name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Centered>
        <AlignRight>
          <BtnSecondary onClick={() => preClose()}>Cancel</BtnSecondary>
          <BtnPrimary onClick={handleSubmit}>Save</BtnPrimary>
        </AlignRight>
      </TemplateContainer>
    </Fragment>
  );
};

export default Template;
