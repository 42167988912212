import styled from "styled-components";

const TemplateContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 90%;
  padding: 1.3rem;
  border-radius: 20px;
  top: 50%;
  margin: 3%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const FormGroup = styled.div`
  display: grid;
  span {
    color: var(--black2);
    margin-left: 8px;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => (props.pt && `padding-top: ${props.pt}`)};
`;

const AlignRight = styled.div`
  display: flex;
  justify-content: right;
`;

const  StyledIcon = styled.span`
  position: relative;
  display: block;
  min-width: 60px;
  line-height: 70px;
  text-align: center;
  ion-icon{
    font-size: 1.75em;
  }
`;

export { TemplateContainer, Form, FormGroup, Centered, AlignRight, StyledIcon };
