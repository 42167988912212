

import { ArticleSave, ArticleUpdate } from "../../domain/models/article.model";
import { http } from "../http";

export const articleRepository = {
  getAll: async () => {
    return await http.get(`/article`);
  },

  getByName: async (name: String) => {
    return await http.get(`/article/getByName/${name}`);
  },

  create: async (article: ArticleSave) => {
    return await http.post('/article', article);
  },

  update: async (article: ArticleUpdate) => {
    const {id} = article;
    delete article.id;
    return await http.patch(`/article/${id}`, article);
  },

  _delete: async (id: Number) => {
    return await http.delete(`/article/${id}`);
  },

//   changePassword: async (data: object) => {
//     return await http.post<ChangePasswordRequestDTO>(`${config.INTERNAL_URL_BACKEND}/changePassword`, data);
//   }
};